import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Input } from '@/components/ui/input';
import { Search, Crown } from 'lucide-react';
import { Badge } from '@/components/ui/badge';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { cn } from '@/lib/utils';

interface AddComponentDialogProps {
  open: boolean;
  onClose: () => void;
  onAdd: (componentType: string) => void;
  isPremiumUser?: boolean;
}

const COMPONENT_TYPES = [
  {
    id: 'topbar',
    name: 'Top Bar',
    description: 'Barra superior con información importante',
    icon: Search,
    category: 'basic',
    tags: ['navegación', 'header'],
    isPremium: false,
  },
  {
    id: 'header',
    name: 'Header',
    description: 'Encabezado principal con navegación',
    icon: Search,
    category: 'basic',
    tags: ['navegación', 'menu'],
    isPremium: false,
  },
  {
    id: 'hero',
    name: 'Hero',
    description: 'Sección principal destacada',
    icon: Search,
    category: 'basic',
    tags: ['principal', 'destacado'],
    isPremium: false,
  },
  {
    id: 'features',
    name: 'Features',
    description: 'Muestra tus servicios o características principales',
    icon: Search,
    category: 'basic',
    tags: ['servicios', 'features'],
    isPremium: false,
  },
  {
    id: 'services',
    name: 'Services',
    description: 'Lista detallada de servicios',
    icon: Search,
    category: 'premium',
    tags: ['servicios', 'ofertas'],
    isPremium: true,
  },
  {
    id: 'testimonials',
    name: 'Testimonials',
    description: 'Testimonios de clientes satisfechos',
    icon: Search,
    category: 'premium',
    tags: ['testimonios', 'reviews'],
    isPremium: true,
  },
  {
    id: 'pricing',
    name: 'Pricing',
    description: 'Planes y precios de tus servicios',
    icon: Search,
    category: 'premium',
    tags: ['precios', 'planes'],
    isPremium: true,
  },
  {
    id: 'contact',
    name: 'Contact',
    description: 'Formulario de contacto',
    icon: Search,
    category: 'basic',
    tags: ['contacto', 'formulario'],
    isPremium: false,
  }
];

export function AddComponentDialog({
  open,
  onClose,
  onAdd,
  isPremiumUser = false,
}: AddComponentDialogProps) {
  const [searchTerm, setSearchTerm] = React.useState('');
  const [selectedCategory, setSelectedCategory] = React.useState('all');

  const filteredComponents = COMPONENT_TYPES.filter(component => {
    const matchesSearch = 
      component.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      component.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
      component.tags.some(tag => tag.toLowerCase().includes(searchTerm.toLowerCase()));
    
    const matchesCategory = 
      selectedCategory === 'all' || 
      (selectedCategory === 'premium' && component.isPremium) ||
      (selectedCategory === 'free' && !component.isPremium);
    
    return matchesSearch && matchesCategory;
  });

  const handleAddComponent = (componentType: string, isPremium: boolean) => {
    if (isPremium && !isPremiumUser) {
      // toast.error('Esta es una característica premium. Por favor, actualiza tu plan para acceder.');
      return;
    }
    onAdd(componentType);
    onClose();
  };

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Añadir Componente</DialogTitle>
          <DialogDescription>
            Elige un componente para añadir a tu página
          </DialogDescription>
        </DialogHeader>

        <div className="space-y-4">
          <div className="flex gap-4 items-center">
            <div className="flex-1 relative">
              <Search className="absolute left-3 top-1/2 -translate-y-1/2 w-4 h-4 text-gray-400" />
              <Input
                placeholder="Buscar componentes..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full pl-9 bg-white/50 backdrop-blur-sm border-gray-200 focus:border-violet-500 transition-colors"
              />
            </div>
            <Tabs defaultValue="all" value={selectedCategory} onValueChange={setSelectedCategory} className="w-auto">
              <TabsList className="bg-white/50 backdrop-blur-sm border border-gray-200">
                <TabsTrigger value="all" className="data-[state=active]:bg-violet-500 data-[state=active]:text-white">Todos</TabsTrigger>
                <TabsTrigger value="free" className="data-[state=active]:bg-violet-500 data-[state=active]:text-white">Gratis</TabsTrigger>
                <TabsTrigger value="premium" className="data-[state=active]:bg-violet-500 data-[state=active]:text-white">Premium</TabsTrigger>
              </TabsList>
            </Tabs>
          </div>

          <ScrollArea className="h-[400px] pr-4">
            <div className="grid grid-cols-2 gap-4">
              {filteredComponents.map((component) => (
                <Button
                  key={component.id}
                  onClick={() => handleAddComponent(component.id, component.isPremium)}
                  variant="ghost"
                  className={cn(
                    "relative flex flex-col items-start h-auto p-4 rounded-lg border hover:border-violet-500 transition-all",
                    "bg-white/50 backdrop-blur-sm shadow-sm hover:shadow-md",
                    "group"
                  )}
                >
                  <div className="flex items-center gap-2 mb-2">
                    {component.icon && <component.icon className="w-5 h-5 text-violet-500" />}
                    <span className="font-medium text-gray-900">{component.name}</span>
                    {component.isPremium && (
                      <Badge variant="secondary" className="bg-violet-100 text-violet-700 border-violet-200">
                        <Crown className="w-3 h-3 mr-1" />
                        Premium
                      </Badge>
                    )}
                  </div>
                  <p className="text-sm text-gray-500 text-left">{component.description}</p>
                  <div className="absolute inset-0 rounded-lg border-2 border-violet-500 opacity-0 group-hover:opacity-100 transition-opacity" />
                </Button>
              ))}
            </div>
          </ScrollArea>
        </div>

        <DialogFooter className="bg-gray-50/50 backdrop-blur-sm border-t px-6 py-4">
          <Button variant="outline" onClick={onClose} className="hover:bg-white/50">
            Cancelar
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
