import React from 'react';
import { 
  Users, 
  Building2,
  ArrowUpRight,
  ArrowDownRight,
  BarChart3,
  Clock,
  Eye,
  FileEdit,
  TrendingUp,
  Star,
  BarChart2,
  Zap,
  Box
} from 'lucide-react';
import { motion } from 'framer-motion';
import { useLandingStore } from '../../store/landingStore';
import { Card } from '../../components/ui/card';

const stats = [
  {
    name: 'Total Subscribers',
    value: '71,897',
    change: '+122',
    changeType: 'positive',
    icon: Users
  },
  {
    name: 'Total de Leads',
    value: '1,234',
    icon: Users,
    change: '+15.3%',
    changeType: 'increase',
    color: 'green'
  },
  {
    name: 'Tasa de Conversión',
    value: '2.4%',
    icon: BarChart3,
    change: '-0.1%',
    changeType: 'decrease',
    color: 'red'
  },
  {
    name: 'Visitas Totales',
    value: '45.2K',
    icon: Building2,
    change: '+23.1%',
    changeType: 'increase',
    color: 'purple'
  }
];

const recentActivity = [
  {
    id: 1,
    event: 'Nuevo lead capturado',
    page: 'Landing de Producto',
    time: 'hace 2 minutos',
    icon: Users,
    color: 'green'
  },
  {
    id: 2,
    event: 'Página publicada',
    page: 'Showcase de Servicios',
    time: 'hace 1 hora',
    icon: Building2,
    color: 'blue'
  },
  {
    id: 3,
    event: 'Alerta de tráfico alto',
    page: 'Landing Principal',
    time: 'hace 3 horas',
    icon: BarChart3,
    color: 'yellow'
  }
];

const topPages = [
  {
    name: 'Landing de Producto',
    views: '12.5K',
    conversion: '3.2%',
    rating: 4.8
  },
  {
    name: 'Showcase de Servicios',
    views: '8.3K',
    conversion: '2.9%',
    rating: 4.5
  },
  {
    name: 'Landing Principal',
    views: '6.7K',
    conversion: '2.1%',
    rating: 4.2
  }
];

export function DashboardHome() {
  const { components, addComponent } = useLandingStore();
  
  // Limpiamos el localStorage al montar el componente
  React.useEffect(() => {
    localStorage.removeItem('landing-store');
  }, []);

  return (
    <div className="p-8">
      <div className="mb-8">
        <h1 className="text-4xl font-bold bg-gradient-to-r from-violet-600 to-indigo-600 bg-clip-text text-transparent">
          Panel de Control
        </h1>
        <p className="mt-2 text-gray-600">Bienvenido de nuevo! Aquí está lo que está sucediendo con tus landing pages.</p>
      </div>

      <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-4">
        {/* Landing Pages Card */}
        <div className="rounded-xl bg-white/50 backdrop-blur-lg border border-violet-100/20 p-6 shadow-lg hover:shadow-xl transition-all duration-300">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-2">
              <div className="p-2 rounded-lg bg-violet-100">
                <FileEdit className="h-6 w-6 text-violet-600" />
              </div>
              <h3 className="text-sm font-medium text-gray-600">Landing Pages</h3>
            </div>
            <span className="text-xs px-2 py-1 rounded-full bg-violet-100 text-violet-600">+2 nuevas</span>
          </div>
          <p className="mt-4 text-3xl font-bold text-gray-900">12</p>
          <p className="mt-1 text-sm text-gray-500">4 activas</p>
        </div>

        {/* Visitas Card */}
        <div className="rounded-xl bg-white/50 backdrop-blur-lg border border-violet-100/20 p-6 shadow-lg hover:shadow-xl transition-all duration-300">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-2">
              <div className="p-2 rounded-lg bg-indigo-100">
                <BarChart2 className="h-6 w-6 text-indigo-600" />
              </div>
              <h3 className="text-sm font-medium text-gray-600">Visitas Totales</h3>
            </div>
            <span className="text-xs px-2 py-1 rounded-full bg-green-100 text-green-600">+12%</span>
          </div>
          <p className="mt-4 text-3xl font-bold text-gray-900">1,234</p>
          <p className="mt-1 text-sm text-gray-500">+12% vs mes anterior</p>
        </div>

        {/* Conversiones Card */}
        <div className="rounded-xl bg-white/50 backdrop-blur-lg border border-violet-100/20 p-6 shadow-lg hover:shadow-xl transition-all duration-300">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-2">
              <div className="p-2 rounded-lg bg-fuchsia-100">
                <Zap className="h-6 w-6 text-fuchsia-600" />
              </div>
              <h3 className="text-sm font-medium text-gray-600">Conversiones</h3>
            </div>
            <span className="text-xs px-2 py-1 rounded-full bg-green-100 text-green-600">+0.5%</span>
          </div>
          <p className="mt-4 text-3xl font-bold text-gray-900">3.2%</p>
          <p className="mt-1 text-sm text-gray-500">+0.5% vs mes anterior</p>
        </div>

        {/* Ingresos Card */}
        <div className="rounded-xl bg-white/50 backdrop-blur-lg border border-violet-100/20 p-6 shadow-lg hover:shadow-xl transition-all duration-300">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-2">
              <div className="p-2 rounded-lg bg-emerald-100">
                <Box className="h-6 w-6 text-emerald-600" />
              </div>
              <h3 className="text-sm font-medium text-gray-600">Ingresos</h3>
            </div>
            <span className="text-xs px-2 py-1 rounded-full bg-green-100 text-green-600">+15%</span>
          </div>
          <p className="mt-4 text-3xl font-bold text-gray-900">$2,345</p>
          <p className="mt-1 text-sm text-gray-500">+15% vs mes anterior</p>
        </div>
      </div>

      {/* Recent Activity Section */}
      <div className="mt-8">
        <h2 className="text-2xl font-semibold text-gray-900 mb-4">Actividad Reciente</h2>
        <div className="rounded-xl bg-white/50 backdrop-blur-lg border border-violet-100/20 overflow-hidden">
          <div className="divide-y divide-gray-200">
            {/* Activity items would go here */}
            <div className="p-4 hover:bg-violet-50/50 transition-colors">
              <div className="flex items-center space-x-4">
                <div className="p-2 rounded-lg bg-violet-100">
                  <FileEdit className="h-5 w-5 text-violet-600" />
                </div>
                <div>
                  <p className="text-sm font-medium text-gray-900">Nueva landing page creada</p>
                  <p className="text-sm text-gray-500">Hace 2 minutos</p>
                </div>
              </div>
            </div>
            {/* More activity items... */}
          </div>
        </div>
      </div>
    </div>
  );
}