import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { useAuthStore } from '../store/auth';

export function ProtectedRoute() {
  const { user: firebaseUser, loading } = useAuth();
  const { isAuthenticated } = useAuthStore();

  if (loading) {
    return (
      <div className="flex min-h-screen items-center justify-center">
        <div className="text-center">
          <div className="mb-4 h-12 w-12 animate-spin rounded-full border-4 border-violet-600 border-t-transparent"></div>
          <p className="text-gray-600">Cargando...</p>
        </div>
      </div>
    );
  }

  // Verificar tanto Firebase como Zustand
  if (!firebaseUser || !isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return <Outlet />;
}