import { Link } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { Rocket, ArrowRight, Layout, BarChart3, Users, Check, Star } from 'lucide-react';

export function Home() {
  return (
    <div className="relative isolate">
      {/* Sección Hero */}
      <div className="bg-gradient-to-r from-violet-100 via-indigo-100 to-violet-100 relative overflow-hidden">
        <div className="absolute inset-0 bg-[url('/grid.svg')] bg-center [mask-image:linear-gradient(180deg,white,rgba(255,255,255,0))] opacity-20"></div>
        <div className="relative px-6 pt-14 lg:px-8">
          <div className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80">
            <div className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-violet-500 to-indigo-500 opacity-20 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]" />
          </div>
          <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
            <div className="text-center">
              <div className="flex justify-center mb-8">
                <div className="p-3 rounded-full bg-white/80 backdrop-blur-sm border border-violet-200 shadow-lg shadow-violet-100/50">
                  <Rocket className="h-12 w-12 text-violet-600" />
                </div>
              </div>
              <h1 className="text-6xl font-bold tracking-tight sm:text-7xl bg-gradient-to-r from-violet-600 via-indigo-600 to-violet-600 text-transparent bg-clip-text animate-gradient">
                LANDER
              </h1>
              <p className="text-2xl font-semibold mt-4 bg-gradient-to-r from-violet-700 to-indigo-700 text-transparent bg-clip-text">
                Crea landing pages impactantes en minutos
              </p>
              <p className="mt-6 text-lg leading-8 text-gray-600 bg-white/80 backdrop-blur-sm p-4 rounded-lg border border-violet-100">
                Construye, lanza y optimiza tus páginas de aterrizaje sin necesidad de programar. Convierte más visitantes en clientes con nuestro potente constructor de landing pages.
              </p>
              <div className="mt-10 flex items-center justify-center gap-x-6">
                <Link to="/register">
                  <Button variant="default" size="lg" className="bg-gradient-to-r from-violet-600 to-indigo-600 hover:from-violet-700 hover:to-indigo-700 gap-2">
                    Empezar Ahora <ArrowRight className="h-4 w-4" />
                  </Button>
                </Link>
                <Link to="/demo">
                  <Button variant="outline" size="lg" className="border-violet-200 text-violet-700 hover:bg-violet-50">
                    Ver Demo
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Sección de Características */}
      <div className="relative bg-white py-24 sm:py-32">
        <div className="absolute inset-0 bg-[url('/stars.svg')] bg-repeat opacity-5"></div>
        <div className="mx-auto max-w-7xl px-6 lg:px-8 relative">
          <div className="mx-auto max-w-2xl lg:text-center mb-16">
            <h2 className="text-base font-semibold leading-7 text-violet-600">Todo lo que necesitas</h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-violet-600 sm:text-4xl">
              Características poderosas para negocios modernos
            </p>
          </div>
          <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
            <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
              <div className="flex flex-col bg-white/80 p-6 rounded-2xl backdrop-blur-sm border border-violet-100">
                <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-violet-600">
                  <Layout className="h-5 w-5 flex-none text-violet-600" />
                  Constructor de Landing Pages
                </dt>
                <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
                  <p className="flex-auto">Crea páginas profesionales con nuestro constructor drag-and-drop. Sin necesidad de código.</p>
                </dd>
              </div>
              <div className="flex flex-col bg-white/80 p-6 rounded-2xl backdrop-blur-sm border border-violet-100">
                <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-violet-600">
                  <BarChart3 className="h-5 w-5 flex-none text-violet-600" />
                  Análisis y Estadísticas
                </dt>
                <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
                  <p className="flex-auto">Monitorea tu rendimiento con análisis detallados y métricas de conversión.</p>
                </dd>
              </div>
              <div className="flex flex-col bg-white/80 p-6 rounded-2xl backdrop-blur-sm border border-violet-100">
                <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-violet-600">
                  <Users className="h-5 w-5 flex-none text-violet-600" />
                  Gestión de Leads
                </dt>
                <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
                  <p className="flex-auto">Administra y nutre tus leads con nuestro sistema CRM integrado.</p>
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>

      {/* Sección de Testimonios */}
      <div className="bg-gradient-to-b from-violet-100 to-indigo-100 py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:text-center">
            <h2 className="text-base font-semibold leading-7 text-violet-600">Testimonios</h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-violet-600 sm:text-4xl">
              Respaldado por miles de empresas
            </p>
          </div>
          <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            {/* Testimonios cards con efecto glassmorphism */}
            <div className="flex flex-col bg-white/80 p-6 backdrop-blur-sm rounded-2xl border border-violet-100">
              <div className="flex gap-x-1">
                {[...Array(5)].map((_, i) => (
                  <Star key={i} className="h-5 w-5 text-yellow-400 fill-yellow-400" />
                ))}
              </div>
              <blockquote className="mt-4 flex-1 text-lg leading-7 text-gray-600">
                "Esta plataforma ha transformado la forma en que creamos landing pages. Es intuitiva, rápida y los resultados son increíbles."
              </blockquote>
              <div className="mt-6">
                <p className="font-semibold text-violet-600">María González</p>
                <p className="text-sm text-gray-500">Directora de Marketing, TechCorp</p>
              </div>
            </div>
            <div className="flex flex-col bg-white/80 p-6 backdrop-blur-sm rounded-2xl border border-violet-100">
              <div className="flex gap-x-1">
                {[...Array(5)].map((_, i) => (
                  <Star key={i} className="h-5 w-5 text-yellow-400 fill-yellow-400" />
                ))}
              </div>
              <blockquote className="mt-4 flex-1 text-lg leading-7 text-gray-600">
                "Las funciones de análisis son increíbles. Hemos aumentado nuestras tasas de conversión en un 150% desde que cambiamos."
              </blockquote>
              <div className="mt-6">
                <p className="font-semibold text-violet-600">Carlos Rodríguez</p>
                <p className="text-sm text-gray-500">CEO, GrowthLabs</p>
              </div>
            </div>
            <div className="flex flex-col bg-white/80 p-6 backdrop-blur-sm rounded-2xl border border-violet-100">
              <div className="flex gap-x-1">
                {[...Array(5)].map((_, i) => (
                  <Star key={i} className="h-5 w-5 text-yellow-400 fill-yellow-400" />
                ))}
              </div>
              <blockquote className="mt-4 flex-1 text-lg leading-7 text-gray-600">
                "El soporte al cliente es excepcional. Todas nuestras preguntas fueron respondidas de manera rápida y profesional."
              </blockquote>
              <div className="mt-6">
                <p className="font-semibold text-violet-600">Ana Martínez</p>
                <p className="text-sm text-gray-500">Fundadora, DesignPro</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Sección de Precios */}
      <div className="bg-gradient-to-b from-indigo-100 to-violet-100 py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:text-center">
            <h2 className="text-base font-semibold leading-7 text-violet-600">Precios</h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-violet-600 sm:text-4xl">
              Elige el plan perfecto para tus necesidades
            </p>
          </div>
          <div className="mx-auto mt-16 grid max-w-lg grid-cols-1 gap-8 lg:max-w-none lg:grid-cols-3">
            {/* Plan Gratuito */}
            <div className="flex flex-col bg-white/80 p-8 backdrop-blur-sm rounded-2xl border border-violet-100">
              <h3 className="text-lg font-semibold leading-7 text-violet-600">Gratuito</h3>
              <p className="mt-4 text-sm leading-6 text-gray-500">Perfecto para comenzar</p>
              <div className="mt-6">
                <p className="flex items-baseline gap-x-1">
                  <span className="text-4xl font-bold text-violet-600">$0</span>
                  <span className="text-sm text-gray-500">/mes</span>
                </p>
              </div>
              <ul className="mt-8 space-y-3">
                {['1 Landing Page', 'Análisis Básico', 'Soporte Comunitario'].map((feature) => (
                  <li key={feature} className="flex gap-x-3 text-gray-600">
                    <Check className="h-5 w-5 text-violet-600" />
                    <span>{feature}</span>
                  </li>
                ))}
              </ul>
              <Link to="/register" className="mt-8">
                <Button variant="outline" className="w-full bg-white/80 text-violet-600 hover:bg-violet-50 border-violet-100">Comenzar Gratis</Button>
              </Link>
            </div>
            {/* Plan Pro */}
            <div className="flex flex-col bg-gradient-to-r from-violet-600 to-indigo-600 p-8 rounded-2xl shadow-xl transform scale-105">
              <h3 className="text-lg font-semibold leading-7 text-white">Pro</h3>
              <p className="mt-4 text-sm leading-6 text-white/90">Ideal para negocios en crecimiento</p>
              <div className="mt-6">
                <p className="flex items-baseline gap-x-1">
                  <span className="text-4xl font-bold text-white">$29</span>
                  <span className="text-sm text-white/90">/mes</span>
                </p>
              </div>
              <ul className="mt-8 space-y-3">
                {[
                  'Landing Pages Ilimitadas',
                  'Análisis Avanzado',
                  'Soporte Prioritario',
                  'Dominios Personalizados',
                  'Pruebas A/B'
                ].map((feature) => (
                  <li key={feature} className="flex gap-x-3 text-white">
                    <Check className="h-5 w-5 text-white" />
                    <span>{feature}</span>
                  </li>
                ))}
              </ul>
              <Link to="/register" className="mt-8">
                <Button variant="secondary" className="w-full bg-white text-violet-600 hover:bg-white/90">Prueba Gratuita</Button>
              </Link>
            </div>
            {/* Plan Empresarial */}
            <div className="flex flex-col bg-white/80 p-8 backdrop-blur-sm rounded-2xl border border-violet-100">
              <h3 className="text-lg font-semibold leading-7 text-violet-600">Empresarial</h3>
              <p className="mt-4 text-sm leading-6 text-gray-500">Para grandes organizaciones</p>
              <div className="mt-6">
                <p className="flex items-baseline gap-x-1">
                  <span className="text-4xl font-bold text-violet-600">$99</span>
                  <span className="text-sm text-gray-500">/mes</span>
                </p>
              </div>
              <ul className="mt-8 space-y-3">
                {[
                  'Todo lo incluido en Pro',
                  'Gerente de Cuenta Dedicado',
                  'Soporte con SLA',
                  'Integraciones Personalizadas',
                  'Colaboración en Equipo'
                ].map((feature) => (
                  <li key={feature} className="flex gap-x-3 text-gray-600">
                    <Check className="h-5 w-5 text-violet-600" />
                    <span>{feature}</span>
                  </li>
                ))}
              </ul>
              <Link to="/register" className="mt-8">
                <Button variant="outline" className="w-full bg-white/80 text-violet-600 hover:bg-violet-50 border-violet-100">Contactar Ventas</Button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* Sección CTA */}
      <div className="bg-gradient-to-r from-violet-600 to-indigo-600 py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl text-center">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
              ¿Listo para comenzar?
            </h2>
            <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-white">
              Únete a miles de empresas que ya están creando landing pages impactantes con nuestra plataforma.
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <Link to="/register">
                <Button variant="secondary" size="lg" className="bg-white text-violet-600 hover:bg-white/90 gap-2">
                  Comienza a Construir <ArrowRight className="h-4 w-4" />
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}